import React from 'react';
import { Drawer, styled } from '@mui/material';
import { Color } from '../../templates/style';

const StyledDrawer = styled(Drawer)(() => ({
    ".MuiDrawer-paperAnchorDockedLeft": {
        left: 'var(--drawer-width, 300px)',
        transition: "left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms"
    },
    ".MuiDrawer-paper": {
        top: "64px",
        borderLeft: `1px solid ${Color.primary}`, // Add left border here
    }
}));

const SecondaryDrawer = (props) => {
    return <StyledDrawer {...props} />
}

export default SecondaryDrawer;