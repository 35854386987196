export const APP_ENV = process.env.REACT_APP_WEBAPP_ENV;
console.log("APP ENVIRONMENT: " + APP_ENV)
let api_base_url = "http://127.0.0.1:3000"; //default

if (APP_ENV === 'dev') {
    api_base_url = 'http://127.0.0.1:3000';
}
else if (APP_ENV === 'test') {
    api_base_url = `https://api.test.sylvamet.ch`;
}
else if (APP_ENV === 'prod') {
    api_base_url = `https://api.sylvamet.ch`;
}

export const API_BASE_URL = api_base_url;
export const JASPER_BASE_URL = 'http://84.16.79.110';
//export const FORST_CONTROL_BASE_URL = 'https://stage2.forstcontrol.ch/api/v1';
export const FORST_CONTROL_BASE_URL = 'https://www.forstcontrol.ch/api/v1';

export const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
