import { styled } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography/index';
import Button from '@material-ui/core/Button';
import { Search } from '@material-ui/icons';
import withStyles from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import * as MaterialColor from "@material-ui/core/colors";

export const Color = {
    primary: 'rgb(236, 125, 0)',
    primary_03: 'rgba(236, 125, 0, 0.3)',
    primary_005: 'rgba(236, 125, 0, 0.05)',
    primary_008: 'rgba(236, 125, 0, 0.008)',
    primary_hover: 'rgb(217, 112, 0)',
    secondary: "white",
    black: "black",
    valid: 'rgb(6,130,64)',
    valid_hover: 'rgb(0,110,29)',
    invalid: 'rgb(207,66,51)',
    invalid_hover: 'rgb(207,15,28)',
    error: '#F00',
    ternary: 'rgb(0, 127, 178)',
    disable: 'rgba(0,0,0,0.26)',
    green: MaterialColor.green,
    red: MaterialColor.red,
};

export const Transition = {
    drawer: "225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
}

export const ImagePath = {
    iconArchived: '/img/icon_archived.svg',
    iconMartelage: '/img/icon_martelage.svg',
    iconCubage: '/img/icon_cubage.svg',
    iconImpression: '/img/icon_impression.svg',
    iconMenuExport: '/img/menu/export.svg',
    iconGestionDonnee: '/img/icon_gestion_donnes.svg',
    iconLotsCreation: '/img/icon_creation_lot.svg',
    iconGestionPiles: '/img/icon_gestion_pile.svg',
    iconReglages: '/img/icon_reglages.svg',
    iconProfil: '/img/icon_profile.png',
    loadingBackground: '/img/SYL_ecran-de-chargement-surcouche.png',
    background: '/img/background.png',
    logoHeadBar: '/img/logo2.png',
    logo: '/img/logo1.png',
    logoIcon: '/img/logo_icon.png',
    iconCubagePile: '/img/icon_cubage_pile.svg',
    iconCubagePileNegative: '/img/icon_cubage_pile_negative.svg',
    iconCubageTige: '/img/icon_cubage_tige.svg',
    iconCubageTigeNegative: '/img/icon_cubage_tige_negative.svg',
    iconCubageMultibilles: '/img/icon_cubage_multibilles.svg',
    iconCubageMultibillesNegative: '/img/icon_cubage_multibilles_negative.svg',
    iconCubageServiceWood: '/img/icon_cubage_service_wood.svg',
    iconCubageServiceWoodNegative: '/img/icon_cubage_service_wood_negative.svg',
    iconCubageFireWood: '/img/icon_cubage_firewood.svg',
    iconCubageFireWoodNegative: '/img/icon_cubage_firewood_negative.svg',
    iconCubageShreddingWood: '/img/icon_cubage_shredding_wood.svg',
    iconCubageShreddingWoodNegative: '/img/icon_cubage_shredding_wood_negative.svg',
    iconCubageIndustrialWood: '/img/icon_cubage_industrial_wood.svg',
    iconCubageIndustrialWoodNegative: '/img/icon_cubage_industrial_wood_negative.svg',
    iconCubageOtherWood: '/img/icon_cubage_other_wood.svg',
    iconCubageOtherWoodNegative: '/img/icon_cubage_other_wood_negative.svg',
    iconAssort200: '/img/assortment/assortment_200.svg',
    iconAssort200Negative: '/img/assortment/assortment_200_n.svg',
    iconAssort201: '/img/assortment/assortment_201.svg',
    iconAssort201Negative: '/img/assortment/assortment_201_n.svg',
    iconAssort202: '/img/assortment/assortment_202.svg',
    iconAssort202Negative: '/img/assortment/assortment_202_n.svg',
    iconAssort203: '/img/assortment/assortment_203.svg',
    iconAssort203Negative: '/img/assortment/assortment_203_n.svg',
    iconAssort204: '/img/assortment/assortment_204.svg',
    iconAssort204Negative: '/img/assortment/assortment_204_n.svg',
    iconAssort205: '/img/assortment/assortment_205.svg',
    iconAssort205Negative: '/img/assortment/assortment_205_n.svg',
    iconAssort206: '/img/assortment/assortment_206.svg',
    iconAssort206Negative: '/img/assortment/assortment_206_n.svg',
    iconAssort207: '/img/assortment/assortment_207.svg',
    iconAssort207Negative: '/img/assortment/assortment_207_n.svg',
    iconAssort208: '/img/assortment/assortment_208.svg',
    iconAssort208Negative: '/img/assortment/assortment_208_n.svg',
    iconAssort209: '/img/assortment/assortment_209.svg',
    iconAssort209Negative: '/img/assortment/assortment_209_n.svg',
    iconMapLot01: '/img/map/lot/01.svg',
    iconMapLot02: '/img/map/lot/02.svg',
    iconMapLot03: '/img/map/lot/03.svg',
    iconMapLot04: '/img/map/lot/04.svg',
    iconMapLot05: '/img/map/lot/05.svg',
    iconMapLot06: '/img/map/lot/06.svg',
    iconMapLot07: '/img/map/lot/07.svg',
    iconMapLot08: '/img/map/lot/08.svg',
    iconMapLot09: '/img/map/lot/09.svg',
    iconMapLot10: '/img/map/lot/10.svg',
    iconExportPicker: '/img/arrow_circle_down_black_24dp.svg',
    action510: '/img/actions/510.svg',
    action510Negative: '/img/actions/510_n.svg',
    action511: '/img/actions/511.svg',
    action511Negative: '/img/actions/511_n.svg',
    action512: '/img/actions/512.svg',
    action512Negative: '/img/actions/512_n.svg',
    action519: '/img/actions/519.svg',
    action519Negative: '/img/actions/519_n.svg',
    productType210: '/img/product/type_210.svg',
    productType210Negative: '/img/product/type_210_n.svg',
    productType211: '/img/product/type_211.svg',
    productType211Negative: '/img/product/type_211_n.svg',
    productType212: '/img/product/type_212.svg',
    productType212Negative: '/img/product/type_212_n.svg',
    productType219_1: '/img/product/type_219_1.svg',
    productType219_1Negative: '/img/product/type_219_1_n.svg',
    productType219_2: '/img/product/type_219_2.svg',
    productType219_2Negative: '/img/product/type_219_2_n.svg',
    productMap210_fe: '/img/product/map_210_fe.svg',
    productMap210_rx: '/img/product/map_210_rx.svg',
    productMap211_rx: '/img/product/map_211_rx.svg',
    productMap211_fe: '/img/product/map_211_fe.svg',
    productMap212_rx: '/img/product/map_212_rx.svg',
    productMap212_fe: '/img/product/map_212_fe.svg',
    productMap219_1_rx: '/img/product/map_219_1_rx.svg',
    productMap219_1_fe: '/img/product/map_219_1_fe.svg',
    productMap219_2_rx: '/img/product/map_219_2_rx.svg',
    productMap219_2_fe: '/img/product/map_219_2_fe.svg',
    sale_interne: '/img/sale/31X_interne.svg',
    sale_interneNegative: '/img/sale/31X_interne_n.svg',
    sale_externe: '/img/sale/300_externe.svg',
    sale_externeNegative: '/img/sale/300_externe_n.svg',
    'wood_type_Service wood': '/img/icon_cubage_service_wood.svg',
    wood_type_Firewood: '/img/icon_cubage_firewood.svg',
    'wood_type_Shredding wood': '/img/icon_cubage_shredding_wood.svg',
    'wood_type_Industrial wood': '/img/icon_cubage_industrial_wood.svg',
    wood_type_Other: '/img/icon_cubage_other_wood.svg',
    certificate_COBS: '/img/certificates/COBS.png',
    'certificate_Swiss Label': '/img/certificates/SwissLabel.png',
    certificate_FSC: '/img/certificates/FSC.png',
    certificate_PEFC: '/img/certificates/PEFC.png',
    pileMap_fe: '/img/map/pile/pile-fe.svg',
    venteLotMap_rx: '/img/map/vente/lot-vente-rx.svg',
    martelage_title_print: '/img/martelage-titre-print.png',
    feuillu: '/img/print/feuillu.png',
    resineux: '/img/print/resineux.png',
    mapPileAssort200: '/img/map/pile/assort_200.svg',
    mapPileAssort201: '/img/map/pile/assort_201.svg',
    mapPileAssort202: '/img/map/pile/assort_202.svg',
    mapPileAssort203: '/img/map/pile/assort_203.svg',
    mapPileAssort204: '/img/map/pile/assort_204.svg',
    mapPileAssort205: '/img/map/pile/assort_205.svg',
    mapPileAssort206: '/img/map/pile/assort_206.svg',
    mapPileAssort207: '/img/map/pile/assort_207.svg',
    mapPileAssort208: '/img/map/pile/assort_208.svg',
    mapPileAssort209: '/img/map/pile/assort_209.svg',
    mapStemAssort200: '/img/map/tige/assort_200.svg',
    mapStemAssort201: '/img/map/tige/assort_201.svg',
    mapStemAssort202: '/img/map/tige/assort_202.svg',
    mapStemAssort203: '/img/map/tige/assort_203.svg',
    mapStemAssort204: '/img/map/tige/assort_204.svg',
    mapStemAssort205: '/img/map/tige/assort_205.svg',
    mapStemAssort206: '/img/map/tige/assort_206.svg',
    mapStemAssort207: '/img/map/tige/assort_207.svg',
    mapStemAssort208: '/img/map/tige/assort_208.svg',
    mapStemAssort209: '/img/map/tige/assort_209.svg',
    iconMapSaleLot: '/img/map/sale_lot/icon.svg',
    iconMapCut: '/img/map/cut/icon.svg',
};

// Hooks
export const Text = styled(Typography)({
    color: Color.primary,
    fontSize: "20px",
    display: "ruby",
});

export const TextDisable = styled(Typography)({
    color: 'red',
    fontSize: "20px",
    display: "ruby",
});

export const MenuText = styled(Typography)({
    color: Color.primary,
    textAlign: "center",
    padding: "10px",
    textDecoration: "none",
    fontSize: "20px",
});

export const MenuTextBold = styled(MenuText)({
    textAlign: "left",
    fontWeight: "bold",
});

export const TableTitle = styled(Typography)({
    color: Color.primary,
    fontWeight: "bold",
    fontSize: "2em",
});

export const SearchIcon = styled(Search)({
    fill: Color.primary
});

export const ValidateButton = styled(Button)({
    color: Color.secondary,
    backgroundColor: Color.valid,
    "&:hover": {
        backgroundColor: Color.valid_hover,
    }
});

export const CancelButton = styled(Button)({
    color: Color.secondary,
    backgroundColor: Color.invalid,
    "&:hover": {
        backgroundColor: Color.invalid_hover,
    }
});

export const FormTitle = styled(Typography)({
    color: Color.primary,
    fontWeight: "bold",
});

export const HeadTableCell = withStyles(theme => ({
    head: {
        backgroundColor: 'black',
        color: theme.palette.common.white + " !important",
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

// Styles
export const SylvaMetStyles = () => ({
    noDecoration: {
        textDecoration: "none !important",
    },
    fabIcon: {
        marginLeft: "auto",
        marginRight: "auto",
        display: "table",
    },
    stateTag: {
        background: Color.primary,
        color: Color.secondary,
        fontWeight: 'bold',
        padding: "5px 10px",
        borderRadius: "20px",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        textWrap: "wrap",
        textAlign: "center",
    },
})