import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './store';
import { I18nextProvider, reactI18nextModule, withI18n } from "react-i18next";
import i18next from "i18next";
import detector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import { API_BASE_URL, GOOGLE_ANALYTICS_ID } from "./config";
import { axiosPost } from "./axios";
import ReactGA from 'react-ga';

// const resources = {
//     fr: {
//         translation: fetch('translations/fr.json')
//     },
//     de: {
//         translation: fetch('translations/de.json')
//
//     },
//     it: {
//         translation: fetch('translations/it.json')
//
//     }
// };

i18next
    .use(detector)
    .use(Backend)
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
        backend: {
            loadPath: API_BASE_URL + '/translations/{{lng}}.json'
        },
        saveMissing: false, // send not translated keys to endpoint
        missingKeyHandler: function (lng, ns, key, fallbackValue) {
            if (process.env.NODE_ENV === 'development') {
                axiosPost(API_BASE_URL + '/translations/' + lng + '.json', { term: key }).then((res) => { })
            }
        },
        fallbackLng: localStorage.getItem("i18nextLng"),
        whitelist: ['fr', 'de', 'it'],
        keySeparator: false
    });



const AppWithI18n = withI18n()(App); // pass `t` function to App

ReactGA.initialize(GOOGLE_ANALYTICS_ID);

ReactDOM.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18next}>
            <Router basename={'/'}>
                <AppWithI18n />
            </Router>
        </I18nextProvider>
    </Provider>,
    document.getElementById('root'))


