import { ACTIONS } from "../actions/_actions_types";

const initialState = {
    all: {},
    current: {},
    searched: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.ACTION.GET_ALL:
            return {
                ...state,
                all: action.payload.actions
            };
        case ACTIONS.ACTION.POST:
            return { ...state, all: [...state.all, action.payload] };
        case ACTIONS.ACTION.PUT:
            return {
                ...state,
                all: [
                    ...state.all.filter(actAction => actAction.id_action != action.payload.action.id_action),
                    action.payload.action
                ]
            };
        case ACTIONS.ACTION.DELETE:
            return {
                ...state,
                all: state.all.filter(actAction => actAction.id_action != action.payload.id)
            };
        case ACTIONS.ACTION.GET:
            return {
                ...state,
                current: action.payload
            };
        case ACTIONS.ACTION.SEARCH:
            return {
                ...state,
                searched: action.payload.data
            };
        case ACTIONS.ERROR:
            return state;
        default:
            return state;
    }
}