import React from "react";
import { CardMedia, Box, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { Color } from "../../templates/style";

const MenuListText = styled(Typography)({
    textAlign: "center",
    padding: "8px 0px",
    textDecoration: "none",
    fontSize: "20px",
    overflow: "clip",
});

const MenuListItemData = styled(Link)({
    display: "flex",
    width: "100%",
    borderRadius: 8,
    backgroundColor: "transparent",
    color: "rgba(0, 0, 0, 0.87)",
    "&:hover": {
        backgroundColor: Color.primary_008,
        color: Color.primary,
    },
    "&:visited": {
        color: Color.primary,
    },
    textDecoration: "none !important",
    width: 300,
})

const MenuListItem = ({ drawerExpanded, t, link, image, title }) => {
    return (
        <MenuListItemData to={link} variant="body1"
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}>
            <CardMedia
                component="img"
                image={image}
                style={{ padding: "8px 12px", maxHeight: "66px", maxWidth: "72px" }}
            />
            {drawerExpanded ? <MenuListText>{t(title)}</MenuListText> : null}
        </MenuListItemData>
    );
}

export default withNamespaces()(MenuListItem);

