import { API_BASE_URL } from '../config';
import { get, put, del } from './utils';
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "../axios";
import { ACTIONS } from "./_actions_types";
import { displayErrorToast, displaySuccessToast } from "./toast";

export const MEASUREMENT_PILE_SUCCESS = 'MEASUREMENT_PILE_SUCCESS';
export const fetchSuccess = data => ({
    type: MEASUREMENT_PILE_SUCCESS,
    data
});

export const MEASUREMENT_PILE_ERROR = 'MEASUREMENT_PILE_ERROR';
export const fetchError = error => ({
    type: MEASUREMENT_PILE_ERROR,
    error
});

export const getMeasurementPiles = () => (dispatch) => {
    return get(dispatch, `${API_BASE_URL}/measurement_piles/`)
        .then(({ measurement_piles }) => dispatch(fetchSuccess(measurement_piles)));
};

export const getMeasurementPilesLots = () => (dispatch) => {
    return axiosGet(`${API_BASE_URL}/measurement_piles_lots/${localStorage.getItem("company")}`).then((res) => {
        dispatch({
            type: ACTIONS.LOT.MEASUREMENTS,
            payload: res.data.measurement_piles
        });
        return res.data.measurement_piles
    });
};

export const getMeasurementPilesLot = (id_lot) => (dispatch) => {
    return axiosGet(`${API_BASE_URL}/measurement_piles_lot/${id_lot}`).then((res) => {
        dispatch({
            type: ACTIONS.LOT.LOT_MEASUREMENTS,
            payload: res.data.measurement_piles
        });
        return res.data.measurement_piles
    });
};

export const getMeasurementPile = (id_measurement_pile) => (dispatch) => {
    return get(dispatch, `${API_BASE_URL}/measurement_piles/${id_measurement_pile}`)
        .then(({ measurement_pile }) => dispatch(fetchSuccess(measurement_pile)));
};

export const putMeasurementPile = (measurement_pile, displayToast = true) => (dispatch) => {
    return put(dispatch, `${API_BASE_URL}/measurement_piles/${measurement_pile.id_measurement_pile}`, measurement_pile)
        .then((measurement_pile) => {
            if (measurement_pile && measurement_pile.error !== undefined) {
                dispatch(fetchError(measurement_pile))
            }
            dispatch(fetchSuccess(measurement_pile))
            if (displayToast) displaySuccessToast('Pile modifié avec succès');
            return measurement_pile
        }, (error) => {
            fetchError(error)
            displayErrorToast(`Erreur lors de la modification de la pile`);
        });
};

export const deleteMeasurementPile = (id_measurement_pile) => (dispatch) => {
    return del(dispatch, `${API_BASE_URL}/measurement_piles/${id_measurement_pile}`).then(() => {
        displaySuccessToast('Pile supprimée avec succès');
    })
    // .then(({measurement_pile}) => {
    //     dispatch(fetchSuccess(measurement_pile))
    // });
};

export const getMeasurementPileSamples = (id_measurement_pile) => (dispatch) => {
    return axiosGet(`${API_BASE_URL}/measurement_piles/${id_measurement_pile}/measurement_pile_samples`).then((res) => {
        dispatch({
            type: ACTIONS.MEASUREMENT_PILES.GET_MEASUREMENT_PILE_SAMPLES,
            payload: res.data.measurement_pile_samples
        })
        return res.data.measurement_pile_samples
    });
};

export const postMeasurementPileSample = (measurement_pile_sample, id_measurement_pile) => (dispatch) => {
    return axiosPost(`${API_BASE_URL}/measurement_piles/${id_measurement_pile}/measurement_pile_sample`, measurement_pile_sample).then((res) => {
        dispatch({
            type: ACTIONS.MEASUREMENT_PILES.POST_MEASUREMENT_PILE_SAMPLES,
            payload: res.data.measurement_pile_sample
        })
        return res.data.measurement_pile_sample
    }, (error) => {
        dispatch({
            type: ACTIONS.ERROR,
            payload: error.response
        })
    });
};

export const deleteMeasurementPileSample = (id) => (dispatch) => {
    return axiosDelete(`${API_BASE_URL}/measurement_pile_sample/` + id).then((res) => {
        dispatch({
            type: ACTIONS.MEASUREMENT_PILES.DELETE_MEASUREMENT_PILE_SAMPLES,
            payload: { id: id, measurement_pile_sample: res.data }
        })
    }, (error) => {
        dispatch({
            type: ACTIONS.ERROR,
            payload: error.response
        })
    });
};

export const putMeasurementPileSample = (measurement_pile_sample) => (dispatch) => {
    return axiosPut(`${API_BASE_URL}/measurement_pile_sample/${measurement_pile_sample.id_measurement_pile_sample}`, measurement_pile_sample).then((res) => {
        dispatch({
            type: ACTIONS.MEASUREMENT_PILES.PUT_MEASUREMENT_PILE_SAMPLES,
            payload: { measurement_pile_sample: res.data }
        })
    }, (error) => {
        dispatch({
            type: ACTIONS.ERROR,
            payload: error.response
        })
    });
};

export const findMeasurementPileAssortment = (id_essence, id_wood_type) => (dispatch) => {
    return axiosPost(`${API_BASE_URL}/measurement_pile/find_assortment`, {
        "id_essence": id_essence,
        "id_wood_type": id_wood_type,
    }).then((res) => {
        dispatch({
            type: ACTIONS.MEASUREMENT_PILES.FIND_MEASUREMENT_ASSORTMENT,
            payload: res.data.assortment
        })
        return res.data.assortment
    }, (error) => {
        dispatch({
            type: ACTIONS.ERROR,
            payload: error.response
        })
    });
};

export const getMeasurementPilesMap = () => (dispatch) => {
    return axiosGet(`${API_BASE_URL}/measurement_piles_map/`).then((res) => {
        dispatch({
            type: ACTIONS.MEASUREMENT_PILES.MAP_PILES,
            payload: res.data.measurement_piles
        });
        return res.data.measurement_piles
    }, (error) => {
        dispatch({
            type: ACTIONS.ERROR,
            payload: error.response
        })
    });
};


export const getPublicMeasurementPilesLot = (id_lot, token) => (dispatch) => {
    return axiosGet(`${API_BASE_URL}/measurement_piles_lot/${id_lot}/${token}`).then((res) => {
        dispatch({
            type: ACTIONS.LOT.LOT_MEASUREMENTS,
            payload: res.data.measurement_piles
        });
        return res.data.measurement_piles
    });
};