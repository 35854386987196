import { API_BASE_URL } from '../config';
import { axiosGet, axiosPost, axiosPut, axiosDelete } from '../axios';
import { fetchSuccess as fetchRoleSuccess } from './role';
import { fetchSuccess as fetchUserCompanySuccess } from './user_company';
import { fetchSuccess as fetchCompanySuccess } from './company';
import { ACTIONS } from "./_actions_types";
import { displaySuccessToast } from "./toast";
import { authRequest, authSuccess } from './auth';
import { saveToken } from '../local-storage';
import { del, get, put } from './utils';


export const fetchSuccess = data => ({
    type: ACTIONS.USERS.SUCCESS,
    data
});

export const fetchError = error => ({
    type: ACTIONS.USERS.ERROR,
    error
});

export const registerUser = (app_user) => (dispatch) => {
    return axiosPost(`${API_BASE_URL}/users`, app_user).then((res) => {
        dispatch({
            type: ACTIONS.USERS.POST,
            payload: res.data
        })
        displaySuccessToast('Utilisateur créer avec succès');
    });
};


export const getUsers = () => (dispatch) => {
    axiosGet(`${API_BASE_URL}/users/`).then((res) => {
        dispatch({
            type: ACTIONS.USERS.GET_ALL,
            payload: res.data
        })
    });
};

export const getAllUsers = () => (dispatch) => {
    axiosGet(`${API_BASE_URL}/allusers`).then((res) => {
        dispatch({
            type: ACTIONS.USERS.GET_ALL,
            payload: res.data
        })
    });
};


export const deleteUser = (id_user) => (dispatch) => {

    if (localStorage.getItem("user_id") == id_user) {
        alert("You cannot delete your current user")
        return axiosGet(`${API_BASE_URL}/users`)
            .then((res) => {
                dispatch({
                    type: ACTIONS.USERS.GET_ALL,
                    payload: res.data
                })
            })
    }

    return axiosDelete(`${API_BASE_URL}/users/${id_user}`)
        .then(() => {
            dispatch({
                type: ACTIONS.USERS.DELETE
            })
            displaySuccessToast('Utilisateur supprimé avec succès');
        })
};

export const getUser = (id_user) => (dispatch) => {
    return get(dispatch, `${API_BASE_URL}/users/${id_user}`)
        .then((users) => dispatch({
            type: ACTIONS.USERS.GET,
            payload: users
        }));
};

export const refreshUser = (token, id_user, id_company) => (dispatch) => {
    dispatch(authRequest());
    saveToken(token, id_user, id_company);
    return axiosGet(`${API_BASE_URL}/users/self`)
        .then((res) => {
            const app_user = res.data.app_user
            saveToken(token, app_user.id_user, app_user.companies.length > 0 ? app_user.companies[0].id_company : null);
            dispatch(authSuccess(app_user));
        });
};

export const putUser = (app_user) => (dispatch) => {
    return put(dispatch, `${API_BASE_URL}/users/${app_user.id_user}`, app_user)
        .then((users) => {
            dispatch({
                type: ACTIONS.USERS.PUT,
                payload: users
            });
            displaySuccessToast('Utilisateur modifié avec succès');
        });
};

export const changePassword = (pwd, id_user) => (dispatch) => {
    return axiosPut(`${API_BASE_URL}/users/pwd/${id_user}`, { password: pwd }).then(() => {
    });
};

// Roles
export const getUserRoles = (id_user) => (dispatch) => {

    axiosGet(`${API_BASE_URL}/users/${id_user}/roles`).then((res) => {
        dispatch(fetchRoleSuccess(res.data))
    })
};

export const putUserRole = (id_user, id_role, role) => (dispatch) => {
    return put(dispatch, `${API_BASE_URL}/users/${id_user}/roles/${id_role}`, role)
        .then(({ roles }) => dispatch(fetchRoleSuccess(roles)));
};

export const deleteUserRole = (id_user, id_role) => (dispatch) => {
    return del(dispatch, `${API_BASE_URL}/users/${id_user}/roles/${id_role}`)
        .then(({ roles }) => dispatch(fetchRoleSuccess(roles)));
};

// Companies
export const getUserCompanies = (id_user) => (dispatch) => {

    axiosGet(`${API_BASE_URL}/users/${id_user}/companies`)
        .then((res) => dispatch(fetchCompanySuccess(res.data)))
        .catch((err) => dispatch(fetchError(err)));
};

export const askCompany = (id_user, id_company) => (dispatch) => {
    return put(dispatch, `${API_BASE_URL}/users/${id_user}/companies/${id_company}`,)
        .then(({ company }) => dispatch(fetchUserCompanySuccess(company)));
};

export const removeCompany = (id_user, id_company) => (dispatch) => {
    return del(dispatch, `${API_BASE_URL}/users/${id_user}/companies/${id_company}`)
};

export const searchUsers = (data) => (dispatch) => {
    dispatch({
        type: ACTIONS.USERS.SEARCH,
        payload: { data }
    })

};